import React from "react"
import Layout from "../../components/layout-team-access"
import accountStyles from "../../components/account.module.scss"

const Membership = () => (
  <Layout>
    <div className="container" style={({'minHeight':`50vh`})}>
      <p style={({
        'position': `absolute`,
        'margin': `4em auto`,
        'zIndex': -1,
        'left': `50%`,
        'marginLeft': `-70px`
      })}>Loading...</p>
      <div className="container">
        <iframe 
          className={accountStyles.iframe}
          title="Account login"
          id="mindbody_branded_web_cart_modal" 
          frameBorder="0" 
          marginWidth="0" 
          marginHeight="0" 
          scrolling="yes"
          src="https://widgets.healcode.com/sites/62245/cart/add_contract?mbo_item_id=105" />
      </div>
    </div>
  </Layout>
)

export default Membership